<template>
    <div v-if="localOperarios.operario">
        <div class="card" v-for="operario in Object.entries(localOperarios.operario)" :key="operario[0]">
            <div  class="card-header bg-gray">
                <h3 class="card-title" v-if="operario[1].Cliente"><i class="fas fa-list mr-2"></i>{{ $t('general.operario') }}
                    {{operario[1].Cliente.trabajos[0].datos.nombre_operario}} </h3>
                <h3 class="card-title" v-else-if="operario[1].Compañia"><i class="fas fa-list mr-2"></i>{{
                    $t('general.operario') }} {{operario[1].Compañia.trabajos[0].datos.nombre_operario}}</h3>
                <h3 class="card-title" v-else-if="operario[1]._"><i class="fas fa-list mr-2"></i>{{ $t('general.operario') }}
                    {{ operario[1]._.trabajos[0].datos.nombre_operario }}</h3>
                <h3 class="card-title" v-else-if="operario[1].operario"><i class="fas fa-list mr-2"></i>{{
                    $t('general.operario') }} {{ operario[1].operario.trabajos[0].datos.nombre_operario }}</h3>
                <div class="card-tools">
                    <!--<button type="button" class="btn btn-tool" title="Subsanar" style="color:white;" @click="subsanarTrabajos(operario[1])"><i class="fas fa-tools"></i>&nbsp;Subsanar</button>-->
                    <button type="button" class="btn btn-tool" title="Revisar" style="color:white;" @click="revisarTrabajos(operario[1])"><i class="fas fa-check-double"></i>&nbsp;Revisar</button>
                    <button type="button" class="btn btn-tool" title="Marcar facturar" style="color:white;" @click="facturarTrabajos(operario[1])"><i class="fas fa-file-invoice-dollar"></i>&nbsp;Marcar facturar</button>
                    &nbsp;
                    &nbsp;
                    <span :title="$t('general.total') + ' ' + $t('general.a cliente')" class="badge bg-success mr-2">{{
                        operario[1].totales.cliente}}&euro;</span>
                    <span :title="$t('general.total') + ' ' + $t('general.a operario')" class="badge bg-danger mr-2">
                        {{ formattedOperarioTotal(operario[1].totales.operario.base, operario[1].totales.operario.contado) }}</span>
                    <span :title="$t('general.rentabilidad')" class="badge bg-info  mr-2">{{operario[1].totales.beneficio.porcentaje}}%</span>
                    &nbsp;
                    <button type="button" class="btn btn-tool" style="color:white" @click="toggleImportarPlantilla(getKeyByValue(localOperarios.operario,operario[1]))"
                        title="Importar plantilla">
                        <i class="fas fa-plus-square mr-1"></i> Importar plantilla
                    </button>
                    <button type="button" class="btn btn-tool" style="color:white" @click="toggleImportarPresupuesto(getKeyByValue(localOperarios.operario,operario[1]))"
                        title="Importar trabajo presupuesto">
                        <i class="fas fa-plus-square mr-1"></i> Importar
                    </button>
                    <button type="button" class="btn btn-tool" style="color:white" @click="toggleComponent()"
                        :title="$t('general.nuevo trabajo')">
                        <i class="fas fa-plus-square mr-1"></i> {{ $t('general.nuevo trabajo') }}
                    </button>
                    <button @click="mostrarNotas(operario[1], operario[0])" title="Mostrar notas" type="button" class="btn btn-tool" >
                        <i class="far fa-sticky-note"></i>
                    </button>
                    <button type="button" class="btn btn-tool" data-card-widget="maximize">
                        <i class="fas fa-expand"></i>
                    </button>
                    <button type="button" class="btn btn-tool" data-card-widget="collapse">
                        <i class="fas fa-minus"></i>
                    </button>
                </div>
            </div>
            <div class="card-body">
                <div v-if="operario[1].mostrarNotas">
                    <div v-if="operario[1].notas.length==0">
                        <label for="">No hay notas para este operario.</label>
                    </div>
                    <div v-if="operario[1].notas.length>0">
                        <div v-for="nota in operario[1].notas" :key="nota" class="direct-chat-msg">
                            <div class="direct-chat-infos clearfix">
                                <!--<span class="direct-chat-name float-left">{{ mensaje.usuario_mensaje }}</span>-->
                                <span class="direct-chat-timestamp float-right">{{ nota.fecha }}</span>
                            </div>
                                <img class="direct-chat-img" src="https://plataforma.apuyen.app/img/trabajadora.jpg" alt="message user image">
                            <div class="direct-chat-text" style="display: grid;">
                                <div style="display: grid; grid-template-columns: 1fr 1fr;">
                                    <p v-html="nota.texto"></p>
                                    <div v-if="nota.archivos.length >0" style="padding: 11px;">
                                        <div class="card card-default"> 
                                            <div class="card-header ch">
                                                <h4 class="card-title">{{$t('general.archivos')}}</h4>                                           
                                            </div>
                                            <div class="card-body" style="padding-bottom: 15px;">
                                                <div class="row" style="justify-content: space-around;row-gap: 10px;">
                                                    <Galleria v-model:activeIndex="nota.activeIndex" v-model:visible="nota.displayCustom" :responsiveOptions="responsiveOptions" :value="nota.archivos" :numVisible="7"
                                                        containerStyle="max-width: 850px" :circular="true" :fullScreen="true" :showItemNavigators="true" :showThumbnails="false">
                                                        <template #item="slotProps">
                                                            <img v-if="urlglobal != 'null.pwgs.app'" class="imgcarr" :src='"https://"+urlglobal+"/documentos.php?documento="  + slotProps.item.documento + "&servicio=" +id' preview :alt="slotProps.item.descripcion"/>
                                                            <img v-if="urlglobal == 'null.pwgs.app'" class="imgcarr" :src='"https://desarrollo.pwgs.app/documentos.php?documento="  + slotProps.item.documento + "&servicio=" +id' preview :alt="slotProps.item.descripcion"/>
                                                        </template>
                                                        <template #thumbnail="slotProps">
                                                            <img v-if="urlglobal != 'null.pwgs.app'" class="imgcarr" :src='"https://"+urlglobal+"/documentos.php?documento="  + slotProps.item.documento + "&servicio=" +id' preview :alt="slotProps.item.descripcion"/>
                                                            <img v-if="urlglobal == 'null.pwgs.app'" class="imgcarr" :src='"https://desarrollo.pwgs.app/documentos.php?documento="  + slotProps.item.documento + "&servicio=" +id' preview :alt="slotProps.item.descripcion"/>
                                                        </template>
                                                    </Galleria>
                                                    <div v-for="(archivos, index) in nota.archivos" :key="archivos">
                                                        <div style="display: grid" v-if="archivos.image==''">
                                                            <i class="fas fa-file fa-8x"></i>
                                                        </div>
                                                        <div v-else-if="archivos.propiedad_cliente!=0">
                                                            <div class="image-container">
                                                                <img style="width: 100px; height: 127px;"  @click="imageClick(nota, index)" v-if="urlglobal != 'null.pwgs.app'" :src='"https://"+urlglobal+"/documentos.php?documento="  + archivos.documento + "&servicio=" +id' width="100" preview :alt="archivos.descripcion" />
                                                                <img style="width: 100px; height: 127px;" @click="imageClick(nota, index)" v-if="urlglobal == 'null.pwgs.app'" :src='"https://desarrollo.pwgs.app/documentos.php?documento="  + archivos.documento + "&servicio=" +id' width="100" preview :alt="archivos.descripcion" />
                                                                <div style="width: 350px;"  class="image-zoom-overlay">
                                                                    <img style="width: 300px; height: 300px;" @click="imageClick(nota, index)" v-if="urlglobal != 'null.pwgs.app'" :src='"https://"+urlglobal+"/documentos.php?documento="  + archivos.documento + "&servicio=" +id' :alt="archivos.descripcion" />
                                                                    <img style="width: 300px; height: 300px;" @click="imageClick(nota, index)" v-if="urlglobal == 'null.pwgs.app'" :src='"https://desarrollo.pwgs.app/documentos.php?documento="  + archivos.documento + "&servicio=" +id' :alt="archivos.descripcion" />
                                                                </div>
                                                            </div>                                                        
                                                        </div>
                                                        <div v-else>
                                                            <div class="image-container">
                                                                <img style="width: 100px; height: 127px;" @click="imageClick(nota, index)" v-if="urlglobal != 'null.pwgs.app'" :src='"https://"+urlglobal+"/documentos.php?documento=" + archivos.documento + "&servicio=" +id' width="100" preview :alt="archivos.descripcion" />
                                                                <img style="width: 100px; height: 127px;" @click="imageClick(nota, index)" v-if="urlglobal == 'null.pwgs.app'" :src='"https://desarrollo.pwgs.app/documentos.php?documento=" + archivos.documento + "&servicio=" +id' width="100" preview :alt="archivos.descripcion" />
                                                                <div style="width: 350px;" class="image-zoom-overlay">
                                                                    <img style="width: 300px; height: 300px;" @click="imageClick(nota, index)" v-if="urlglobal != 'null.pwgs.app'" :src='"https://"+urlglobal+"/documentos.php?documento=" + archivos.documento + "&servicio=" +id' :alt="archivos.descripcion" />
                                                                    <img style="width: 300px; height: 300px;" @click="imageClick(nota, index)" v-if="urlglobal == 'null.pwgs.app'" :src='"https://desarrollo.pwgs.app/documentos.php?documento=" + archivos.documento + "&servicio=" +id' :alt="archivos.descripcion" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="nota.alarmas">
                                    <div v-for="alarma in nota.alarmas" :key="alarma">
                                        <p>{{ $t('general.alarma') }}:</p>
                                        <p>{{ $t('general.usuario') }}:{{ nombre_usuario_alarma }} {{ $t('general.remitente2') }}:{{ nombre_remitente_alarma }}</p>
                                        <p>{{ alarma.fecha_alarma }}{{ hora_alarma }}</p>
                                    </div>
                                    <button  class="btn btn-default">{{$t('general.editaralarma')}}</button>
                                </div>
                                <input type="checkbox" class="checknotas" v-model="nota.revisado" @click="cambiarEstadoNota(nota.id_nota, nota.revisado)"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="showComponent">
                    <trabajosrealizadonuevo :id="id" :operario="getKeyByValue(localOperarios.operario,operario[1])"
                        :servicio="servicio" />
                </div>
                <div v-if="mostrarImportarPresupuesto[getKeyByValue(localOperarios.operario,operario[1])]">
                    <TrabajosImportarPresupuesto 
                        :id="id" 
                        :operario="getKeyByValue(localOperarios.operario,operario[1])"
                        :servicio="servicio" 
                        @trabajos-importados="ocultarImportarPresupuesto(getKeyByValue(localOperarios.operario,operario[1]))" />
                </div>
                <div v-if="mostrarImportarPlantilla[getKeyByValue(localOperarios.operario,operario[1])]">
                    <TrabajosImportarPlantilla 
                        :id="id" 
                        :operario="getKeyByValue(localOperarios.operario,operario[1])"
                        :servicio="servicio" 
                        @plantillas-importadas="ocultarImportarPlantilla(getKeyByValue(localOperarios.operario,operario[1]))" />
                </div>
                <div v-if="operario[1].Cliente" v-show="!ocultarcliente">
                    <trabajosrealizadoconcepto v-for="totales in operario[1].Cliente.trabajos" :key="totales"
                        :totales="totales" :id="id" :servicio="servicio" />
                </div>
                <div v-if="operario[1].Compañia" v-show="!ocultarcliente">
                    <trabajosrealizadoconcepto v-for="totales in operario[1].Compañia.trabajos" :key="totales"
                        :totales="totales" :id="id" :servicio="servicio" />
                </div>
                <!-- Renderizado de Perjudicados -->
                <div v-for="(perjudicado, index) in getPerjudicados(operario[1])" :key="index" v-show="!ocultarcliente">
                    <trabajosrealizadoconcepto
                        v-for="totales in perjudicado.trabajos"
                        :key="totales"
                        :totales="totales"
                        :id="id"
                        :servicio="servicio"
                    />
                </div>
                
                <div v-if="operario[1]._" v-show="!ocultarcliente">
                    <div v-for="totales in operario[1]._.trabajos" :key="totales.id" class="position-relative">
                        <trabajosrealizadoconcepto :totales="totales" :id="id" :servicio="servicio"/>
                    </div>
                </div>
                <div v-if="operario[1].operario" v-show="!ocultaroperario">
                    <trabajosrealizadoconceptoOperario v-for="totales in operario[1].operario.trabajos" :key="totales"
                        :totales="totales" :id="id" :servicio="servicio" />
                </div>
                
            </div>
            <div class="card-footer">
                <div class="row">
                    <div class="col-md-3"></div>
                    <div class="col-md-3">
                        <div class="input-group mb-3">
                            <div class="input-group-prepend ">
                                <span class="input-group-text">{{ $t('general.total') }} {{ $t('general.cliente') }}</span>
                            </div>
                            <input style="text-align: end" type="text" class="form-control" :value="operario[1].totales.cliente + '&euro;'" readonly>
                            <!--
                                <input type="text" v-if="operario.Compañia "  class="form-control" v-model="operario.totales.cliente"  readonly>
                                <input type="text" v-if="operario._"  class="form-control" v-model="operario.totales.cliente"  readonly>
                                <input type="text" v-else class="form-control"  readonly>
                            -->                        
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text">{{ $t('general.total') }} {{ $t('general.coste') }}</span>
                            </div>
                            <input type="text" style="text-align: end" :value="formattedOperarioTotal(operario[1].totales.operario.base, operario[1].totales.operario.contado)"   class="form-control" readonly>
                            <!--<input type="text" v-if="operario.Compañia" v-model="operario.totales.operario.base" class="form-control" readonly>
                            <input type="text" v-if="operario._" v-model="operario.totales.operario.base" class="form-control" readonly>-->
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text">{{ $t('general.rentabilidad')}}</span>
                            </div>
                            <input type="text" style="text-align: end" :value="operario[1].totales.beneficio.importe + '&euro;'" class="form-control" readonly>
                            <input type="text" style="text-align: end" :value="operario[1].totales.beneficio.porcentaje + '%'" class="form-control" readonly :title="$t('general.seleccionado') + ' ' + $t('general.para facturar')">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Galleria from 'primevue/galleria';
import { PwgsApi } from '../../../../services/PwgsApi';
import trabajosrealizadoconcepto from './TrabajoRealizadoConcepto.vue';
import trabajosrealizadoconceptoOperario from './TrabajoRealizadoConceptoOperario.vue';
import trabajosrealizadonuevo from './TrabajoRealizadoNuevo.vue';
import TrabajosImportarPresupuesto from './TrabajosImportarPresupuesto.vue';
import TrabajosImportarPlantilla from './TrabajosImportarPlantilla.vue';

export default ({
    props:[
        'id',
        'servicio',
        'recargar',
        'ocultarcliente',
        'ocultaroperario',
        'totalrent',
        'totaloperario',
        'totalcliente',
        'mostrarfacturar',
        'operariosingular',
        'operarios'
    ],
    setup() {
        return;
    },
    components: {
        trabajosrealizadoconcepto,
        trabajosrealizadoconceptoOperario,
        trabajosrealizadonuevo,
        TrabajosImportarPresupuesto,
        TrabajosImportarPlantilla,
        Galleria,
    },
    data() {
        return {
            showComponent: false,
            mostrarImportarPresupuesto: {},
            mostrarImportarPlantilla: {}, 
//            operarios: [],
            totalescliente: '',
            totalesoperario: '',
            totalesrentabilidad: '',
            suma: [],
            cargar: false,
            localOperarios: {},
            urlglobal: '',
            trabajossub: false,
            trabajosrev: false,
            trabajosfac:false,
            responsiveOptions: [
                {
                    breakpoint: '1024px',
                    numVisible: 5
                },
                {
                    breakpoint: '768px',
                    numVisible: 3
                },
                {
                    breakpoint: '560px',
                    numVisible: 1
                }
            ],
        };
    },
    methods: {
        imageClick(nota, index) {
            nota.activeIndex = index;
            nota.displayCustom = true;
        },
        toggleComponent() {
            this.showComponent = !this.showComponent;
        },
        async subsanarTrabajos(operario) {
            this.trabajossub = !this.trabajossub;
            if(this.trabajossub==true){
            let trabajos = [];
            if(operario.Compañia){
            for (let i = 0; i < operario.Compañia.trabajos.length; i++){
                trabajos.push(operario.Compañia.trabajos[i].datos.idtrabajo_servicio);
            }
            }
            if(operario.operario){
            for (let j = 0; j < operario.operario.trabajos.length; j++){
                trabajos.push(operario.operario.trabajos[j].datos.idtrabajo_servicio);
            }
            }
            if(operario.Cliente){
            for (let k = 0; k < operario.Cliente.trabajos.length; k++){
                trabajos.push(operario.Cliente.trabajos[k].datos.idtrabajo_servicio);
            }
            }
            let subidadatos = { ids_trabajos: trabajos };
            const api = new PwgsApi();
            try{
                await api.put('trabajos/' + this.$props.id + '/subsanar', subidadatos);
                this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Trabajos guardados para subsanar', life: 2000 });
            }
             catch (error) {
                    if (error == "Error: Token no válido") {
                        api.get('logout');
                        localStorage.removeItem('token');
                        this.$router.push('/login');
                    }
                    this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
            }
                }
        },
        async facturarTrabajos(operario) {
            this.trabajosfac = !this.trabajosfac;
            if(this.trabajosfac==true){
            let trabajos = [];
            if(operario.Compañia){
            for (let i = 0; i < operario.Compañia.trabajos.length; i++){
                trabajos.push(operario.Compañia.trabajos[i].datos.idtrabajo_servicio);
                console.log(trabajos);
            }
            }
            /*if(operario.operario){
            for (let j = 0; j < operario.operario.trabajos.length; j++){
                trabajos.push(operario.operario.trabajos[j].datos.idtrabajo_servicio);
            }
            }*/
            if(operario.Cliente){
            for (let k = 0; k < operario.Cliente.trabajos.length; k++){
                trabajos.push(operario.Cliente.trabajos[k].datos.idtrabajo_servicio);
            }
            }
            let subidadatos = { ids_trabajos: trabajos };
            const api = new PwgsApi();
            try{
                await api.put('trabajos/' + this.$props.id + '/facturar-varios', subidadatos);
                this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Trabajos guardados para facturar', life: 2000 });

            }
             catch (error) {
                    if (error == "Error: Token no válido") {
                        api.get('logout');
                        localStorage.removeItem('token');
                        this.$router.push('/login');
                    }
                    this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
            }
                }
        },
        async revisarTrabajos(operario) {
            this.trabajosrev = !this.trabajosrev;
            if(this.trabajosrev==true){
            let trabajos = [];
            if(operario.Compañia){
            for (let i = 0; i < operario.Compañia.trabajos.length; i++){
                trabajos.push(operario.Compañia.trabajos[i].datos.idtrabajo_servicio);
            }
            }
            if(operario.operario){
            for (let j = 0; j < operario.operario.trabajos.length; j++){
                trabajos.push(operario.operario.trabajos[j].datos.idtrabajo_servicio);
            }
            }
            if(operario.Cliente){
            for (let k = 0; k < operario.Cliente.trabajos.length; k++){
                trabajos.push(operario.Cliente.trabajos[k].datos.idtrabajo_servicio);
            }
            }
            let subidadatos = { ids_trabajos: trabajos };
            const api = new PwgsApi();
            try{
                await api.put('trabajos/' + this.$props.id + '/revisar', subidadatos);
                this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Trabajos guardados para revisar', life: 2000 });

            }
             catch (error) {
                    if (error == "Error: Token no válido") {
                        api.get('logout');
                        localStorage.removeItem('token');
                        this.$router.push('/login');
                    }
                    this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
            }
                }
        },
        toggleImportarPresupuesto(operarioId) {
            //this.mostrarImportarPresupuesto = !this.mostrarImportarPresupuesto;
            if (this.mostrarImportarPresupuesto[operarioId]) {
                this.mostrarImportarPresupuesto[operarioId] = !this.mostrarImportarPresupuesto[operarioId];
            } else {
                this.mostrarImportarPresupuesto[operarioId] = true;
            }
        },
        toggleImportarPlantilla(operarioId) {
            if (this.mostrarImportarPlantilla[operarioId]) {
                this.mostrarImportarPlantilla[operarioId] = !this.mostrarImportarPlantilla[operarioId];
            } else {
                this.mostrarImportarPlantilla[operarioId] = true;
            }
        },
        ocultarImportarPresupuesto(operarioId) {
            //this.mostrarImportarPresupuesto = false; // Ocultar el callout de importar
            this.mostrarImportarPresupuesto[operarioId] = false;
            this.$toast.add({ severity: 'success', summary: this.$t('general.guardado'), detail: this.$t('general.datosguardadoscorrectamente'), life: 2000 });
            this.cargardatos(); // Volver a cargar los datos del componente padre
        },
        ocultarImportarPlantilla(operarioId) {
            //this.mostrarImportarPlantilla = false; // Ocultar el callout de importar
            this.mostrarImportarPlantilla[operarioId] = false;
            this.$toast.add({ severity: 'success', summary: this.$t('general.guardado'), detail: this.$t('general.datosguardadoscorrectamente'), life: 2000 });
            this.cargardatos(); // Volver a cargar los datos del componente padre
        },
        formattedOperarioTotal(base, contado) {
            let baseFormatted = `${base}€`;
            if (contado > 0) {
                baseFormatted += ` (A operario ${contado}€)`;
            }
            return baseFormatted;
        },

        async desactivaralarma(idnota, idalarma) {
            const api = new PwgsApi();
            let subidadatos = { id_alarma: idalarma};
            await api.put('servicios/' + this.$props.id + '/notas/' + idnota, subidadatos);
            this.motivo = '';
            this.cargarnotas();
        },
        async cambiarEstadoNota(id_nota, estadoActual) {
            // Aquí puedes manejar la lógica diferente para marcar y desmarcar
            if (estadoActual) {
                this.procesarNotaRevisada(id_nota);
            } else {
                this.procesarNotaNoRevisada(id_nota);
            }
            this.cargarnotas();
        },

        async procesarNotaNoRevisada(idnota) {
            const api = new PwgsApi();
            let body = {tipo: "trabajos_no_revisados", ids_avisos: [idnota], estado: 1};
            await api.put('/avisos/revisar-avisos', body);
        },
        async procesarNotaRevisada(idnota) {
            const api = new PwgsApi();
            let body = {tipo: "trabajos_no_revisados", ids_avisos: [idnota], estado: 0};
            await api.put('/avisos/revisar-avisos', body);
        },
        async cargardatos() { 
            //GET  modulos/pwgsapi/index.php/trabajos/:id_servicio/trabajos-servicio
            const api = new PwgsApi();
            this.localOperarios = await api.get('trabajos/' + this.id + '/trabajos-servicio');            
            if (this.operariosingular > 0) {
                // Filtramos los operarios si 'operariosingular' está definido y es mayor que 0
                for (let operario in this.localOperarios.operario) {
                    if (operario != this.operariosingular) {
                        delete this.localOperarios.operario[operario];       
                    }                    
                }
            }     
            // Calculamos la rentabilidad total
            this.totalesrentabilidad = this.totalcliente - this.totaloperario;
            this.totalesrentabilidad = Math.round((this.totalesrentabilidad * 100).toFixed(2) / this.totalcliente) + "%";
        },
        async cargarNotas(operario, idop){
            if(!operario.notas){
                console.log('entracargarnotas', operario);
                const api = new PwgsApi();
                              
                    const nts = await api.get('/servicios/'+this.id+'/notas?id_operario='+idop);
                    console.log('ntss', nts);
                    var notas = nts;
                    if(notas){
                        operario.notas = notas.map(nota => {
                            return this.comprobarRevisado(nota);
                        });
                        for(let nota of operario.notas){
                            nota.displayCustom = false;
                            nota.activeIndex = 0;
                        }
                    }else{
                        operario.notas = [];
                    }
                    operario.mostrarNotas = false;
                    console.log('operasasa', operario);
               
                }
                operario.mostrarNotas = !operario.mostrarNotas;
        },

        comprobarRevisado(nota) {
            return {
                ...nota,
                revisado: nota.realizado_operario && nota.realizado_operario.toLowerCase() === "si"
            };
        },
        mostrarNotas(operario, id){
            
                this.cargarNotas(operario, id);            
            
        },
        getKeyByValue(object, value) {
            return Object.keys(object).find(key => object[key] === value);
        },
        togglecargar() { 
            this.cargar = !this.cargar;
        },
        getPerjudicados(operario) {
            if (!operario || typeof operario !== 'object' || Object.keys(operario).length === 0) {
                return [];
            }
            return Object.keys(operario)
                .filter(key => key.startsWith('Perjudicado_'))
                .map(key => operario[key]);
        }
    },
    mounted() {
        this.urlglobal = localStorage.getItem("direccion");
        if (this.operariosingular) {
        // Si 'operariosingular' es verdadero, cargamos los datos nosotros mismos
            this.cargardatos();
        } else if (this.operarios && Object.keys(this.operarios).length > 0) {
            // Si 'operarios' está disponible y 'operariosingular' es falso o indefinido, usamos los datos del padre
            this.localOperarios = JSON.parse(JSON.stringify(this.operarios));
        }
    },
    watch: {
        id() { 
            this.localOperarios = [];
            //this.cargardatos();
        },
        cargar() { 
            //this.cargardatos();
        },
        recargar() {
            //this.cargardatos();
        },
        operarios(newVal) {
            if (newVal && Object.keys(newVal).length > 0 ) {
                // Assign the new prop value to the local data property
                this.localOperarios = JSON.parse(JSON.stringify(newVal));
            }
        },
        localOperarios(){
            this.cargarNotas();
        },
        operariosingular(newVal) {
            if (newVal) {
                // Si 'operariosingular' cambia a verdadero, cargamos los datos nosotros mismos
                this.cargardatos();
            } else {
                // Si 'operariosingular' cambia a falso, usamos los datos del padre si están disponibles
                if (this.operarios && Object.keys(this.operarios).length > 0) {
                    this.localOperarios = JSON.parse(JSON.stringify(this.operarios));
                }
            }
        }
    }
})
</script>