<template>
    <div class="callout callout-warning">
        <div class="row">
            <div class="col-md-12">
                <label for="presupuestos">Seleccionar trabajos para importar</label>

                <!-- Mostrar "Cargando..." si está cargando -->
                <div v-if="isLoading" class="loading-spinner">
                    <i class="fas fa-spinner fa-spin"></i> Cargando...
                </div>

                <select multiple v-model="selectedTrabajos" class="form-control" :disabled="isLoading">
                <optgroup v-for="presupuesto in presupuestos" :label="presupuesto.presupuesto.nombre_presupuesto" :key="presupuesto.presupuesto.id_presupuesto">
                    <option
                    v-for="trabajo in presupuesto.trabajos"
                    :key="trabajo.datos.idtrabajo_presupuesto"
                    :value="trabajo.datos.idtrabajo_presupuesto"
                    >
                    {{ trabajo.datos.nombre }}
                    <!-- Condición para mostrar "Importado" -->
                    <small v-if="trabajo.datos.imputado_trabajo_presupuesto === 'SI'" class="badge badge-danger"><i class="fas fa-check"></i> Importado</small>
                    </option>
                </optgroup>
                </select>
            </div>
            <div class="col-md-12 mt-3">
                <button @click="importarTrabajos" class="btn btn-primary">Importar Trabajos</button>
            </div>
        </div>
    </div>
</template>
<script>
import { PwgsApi } from '../../../../services/PwgsApi';
export default ({
    props: ['id','servicio','operario','proveedor'],
    data() {
        return {
            presupuestos: [],
            selectedTrabajos: [],
            isLoading: true // Estado de carga inicializado como true
        };
    },
    methods: {
        async cargarPresupuestosYTrabajos() {
            const api = new PwgsApi();
            try {
                // Obtener presupuestos
                const response = await api.get(`presupuestos/${this.id}`);
                this.presupuestos = response;

                // Obtener los trabajos de cada presupuesto
                for (let presupuesto of this.presupuestos) {
                    const trabajosResponse = await api.get(`presupuestos/${presupuesto.presupuesto.id_presupuesto}/trabajos`);
                    presupuesto.trabajos = trabajosResponse.trabajos;
                }
            } catch (error) {
                console.error('Error al cargar presupuestos o trabajos:', error);
            } finally {
                this.isLoading = false; // Finalizar la carga
            }
        },
        
        async importarTrabajos() {
            const api = new PwgsApi();
            try {
                const payload = {
                    ids_trabajos: this.selectedTrabajos
                };
                if (this.operario) {
                    payload.id_operario = this.operario;
                } else if (this.proveedor) {
                    payload.id_proveedor = this.proveedor;
                }

                await api.post(`trabajos/${this.servicio}/importar-trabajo`, payload);
                //alert('Trabajos importados correctamente');
                //this.$toast.add({ severity: 'success', summary: this.$t('general.guardado'), detail: this.$t('general.datosguardadoscorrectamente'), life: 2000 });
                this.$emit('trabajos-importados');
            } catch (error) {
                //alert('Error al importar trabajos:'+ error	);
                this.$toast.add({ severity: 'error', summary: "Error al importar trabajos", detail: error, life: 5000 });
                //alert('Error al importar trabajos');
            }
        }
    },
    watch: {
        id() {
            this.cargarPresupuestosYTrabajos();
        }
    },
    mounted() {
        this.cargarPresupuestosYTrabajos();
    }
})
</script>