<template>
    <div class="card" v-for="proveedor in localProveedores.proveedor" :key="proveedor">
        <div class="card-header bg-gray">
            <h3 class="card-title" v-if="proveedor.Cliente"><i class="fas fa-list mr-2"></i>{{ $t('general.proveedor') }}
                {{proveedor.Cliente.trabajos[0].datos.nombre_operario}}</h3>
            <h3 class="card-title" v-else-if="proveedor.Compañia"><i class="fas fa-list mr-2"></i>{{
                $t('general.proveedor') }} {{proveedor.Compañia.trabajos[0].datos.nombre_operario}}</h3>
            <h3 class="card-title" v-else-if="proveedor._"><i class="fas fa-list mr-2"></i>{{ $t('general.proveedor') }}
                {{ proveedor._.trabajos[0].datos.nombre_operario }}</h3>
            <h3 class="card-title" v-else-if="proveedor.operario"><i class="fas fa-list mr-2"></i>{{
                $t('general.proveedor') }} {{ proveedor.proveedor.trabajos[0].datos.nombre_operario }}</h3>
            <div class="card-tools">
                <span :title="$t('general.total') + ' ' + $t('general.a cliente')" class="badge bg-success mr-2">{{
                    proveedor.totales.cliente}}&euro;</span>
                <span :title="$t('general.total') + ' ' + $t('general.a proveedor')" class="badge bg-danger mr-2">
                    {{ formattedOperarioTotal(proveedor.totales.operario.base, proveedor.totales.operario.contado) }}</span>
                <span :title="$t('general.rentabilidad')" class="badge bg-info  mr-2">{{
                    proveedor.totales.beneficio.porcentaje}}%</span>
                <button type="button" class="btn btn-tool" style="color:white" @click="toggleImportarPresupuesto()"
                    title="Importar trabajo presupuesto">
                    <i class="fas fa-plus-square mr-1"></i> Importar
                </button>
                <button type="button" class="btn btn-tool" style="color:white" @click="toggleComponent()"
                    :title="$t('general.nuevo trabajo')">
                    <i class="fas fa-plus-square mr-1"></i> {{ $t('general.nuevo trabajo') }}
                </button>
                <button type="button" class="btn btn-tool" data-card-widget="maximize">
                    <i class="fas fa-expand"></i>
                </button>
                <button type="button" class="btn btn-tool" data-card-widget="collapse">
                    <i class="fas fa-minus"></i>
                </button>
            </div>
        </div>
        <div class="card-body">
            <div v-if="showComponent">
                <trabajosrealizadonuevo :id="id" :proveedor="getKeyByValue(localProveedores.proveedor,proveedor)"
                    :servicio="servicio" />
            </div>
            <div v-if="mostrarImportarPresupuesto">
                <TrabajosImportarPresupuesto 
                    :id="id" 
                    :proveedor="getKeyByValue(localProveedores.proveedor,proveedor)" 
                    :servicio="servicio" 
                    @trabajos-importados="ocultarImportarPresupuesto" />
            </div>
            <div v-if="proveedor.Cliente" v-show="!ocultarcliente">
                <trabajosrealizadoconcepto v-for="totales in proveedor.Cliente.trabajos" :key="totales"
                    :totales="totales" :id="id" :servicio="servicio" />
            </div>
            <div v-if="proveedor.Compañia" v-show="!ocultarcliente">
                <trabajosrealizadoconcepto v-for="totales in proveedor.Compañia.trabajos" :key="totales"
                    :totales="totales" :id="id" :servicio="servicio" />
            </div>
            <!-- Renderizado de Perjudicados -->
            <div v-for="(perjudicado, index) in getPerjudicados(proveedor)" :key="index" v-show="!ocultarcliente">
                <trabajosrealizadoconcepto
                    v-for="totales in perjudicado.trabajos"
                    :key="totales"
                    :totales="totales"
                    :id="id"
                    :servicio="servicio"
                />
                <div>{{ console.log('Perjudicado en template:', perjudicado) }}</div>
            </div>
            
            <div v-if="proveedor._" v-show="!ocultarcliente">
                <div v-for="totales in proveedor._.trabajos" :key="totales.id" class="position-relative">
                    <trabajosrealizadoconcepto :totales="totales" :id="id" :servicio="servicio"/>
                </div>
            </div>
            <div v-if="proveedor.proveedor" v-show="!ocultaroperario">
                <trabajosrealizadoconceptoOperario v-for="totales in proveedor.proveedor.trabajos" :key="totales"
                    :totales="totales" :id="id" :servicio="servicio" />
            </div>
            
        </div>
        <div class="card-footer">
            <div class="row">
                <div class="col-md-3"></div>
                <div class="col-md-3">
                    <div class="input-group mb-3">
                        <div class="input-group-prepend ">
                            <span class="input-group-text">{{ $t('general.total') }} {{ $t('general.cliente') }}</span>
                        </div>
                        <input style="text-align: end" type="text" class="form-control" :value="proveedor.totales.cliente + '&euro;'" readonly>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text">{{ $t('general.total') }} {{ $t('general.coste') }}</span>
                        </div>
                        <input style="text-align: end" type="text"  :value="formattedOperarioTotal(proveedor.totales.operario.base, proveedor.totales.operario.contado)"   class="form-control" readonly>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text">{{ $t('general.rentabilidad')}}</span>
                        </div>
                        <input style="text-align: end" type="text" :value="proveedor.totales.beneficio.importe + '&euro;'" class="form-control" readonly>
                        <input style="text-align: end"  type="text" :value="proveedor.totales.beneficio.porcentaje + '%'" class="form-control" readonly :title="$t('general.seleccionado') + ' ' + $t('general.para facturar')">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { PwgsApi } from '../../../../services/PwgsApi';
import trabajosrealizadoconcepto from './TrabajoRealizadoConcepto.vue';
import trabajosrealizadoconceptoOperario from './TrabajoRealizadoConceptoOperario.vue';
import trabajosrealizadonuevo from './TrabajoRealizadoNuevoProveedor.vue';
import TrabajosImportarPresupuesto from './TrabajosImportarPresupuesto.vue';

export default ({
    props:[
        'id',
        'servicio',
        'recargar',
        'ocultarcliente', 
        'ocultaroperario',
        'totalrent',
        'totaloperario',
        'totalcliente',
        'mostrarfacturar',
        'operariosingular',
        'operarios'
    ],
    setup() {
        return;
    },
    components: {
        trabajosrealizadoconcepto,
        trabajosrealizadoconceptoOperario,
        trabajosrealizadonuevo,
        TrabajosImportarPresupuesto
    },
    data() {
        return {
            showComponent: true,
            mostrarImportarPresupuesto: false,
            localProveedores: [],
            totalescliente: '',
            totalesoperario: '',
            totalesrentabilidad: '',
            suma: [],
            cargar: false,
        };
    },
    methods: {
        toggleComponent() {
            this.showComponent = !this.showComponent;
        },
        toggleImportarPresupuesto() {
            this.mostrarImportarPresupuesto = !this.mostrarImportarPresupuesto;
        },
        ocultarImportarPresupuesto() {
            this.mostrarImportarPresupuesto = false; // Ocultar el callout de importar
            this.$toast.add({ severity: 'success', summary: this.$t('general.guardado'), detail: this.$t('general.datosguardadoscorrectamente'), life: 2000 });
            this.cargardatos(); // Volver a cargar los datos del componente padre
        },
        formattedOperarioTotal(base, contado) {
            let baseFormatted = `${base}€`;
            if (contado > 0) {
                baseFormatted += ` (A proveedor ${contado}€)`;
            }
            return baseFormatted;
        },
        async cargardatos() { 
        //GET  modulos/pwgsapi/index.php/trabajos/:id_servicio/trabajos-servicio
            const api = new PwgsApi();
            this.localProveedores = await api.get('trabajos/' + this.$props.id + '/trabajos-servicio');
            //alert("Cargados desde proveedores");
            this.totalesrentabilidad = this.$props.totalcliente - this.$props.totaloperario;
            this.totalesrentabilidad = Math.round((this.totalesrentabilidad * 100).toFixed(2) / this.$props.totalcliente) + "%";
        },
        getKeyByValue(object, value) {
            return Object.keys(object).find(key => object[key] === value);
        },
        togglecargar() { 
            this.cargar = !this.cargar;
        },
        getPerjudicados(operario) {
            if (!operario || typeof operario !== 'object' || Object.keys(operario).length === 0) {
                return [];
            }
            return Object.keys(operario)
                .filter(key => key.startsWith('Perjudicado_'))
                .map(key => operario[key]);
        }
    },
    mounted() {
        if (this.operariosingular) {
            // Si 'operariosingular' es verdadero, cargamos los datos nosotros mismos
            this.cargardatos();
        } else if (this.operarios && Object.keys(this.operarios).length > 0) {
            // Si 'operarios' está disponible y 'operariosingular' es falso o indefinido, usamos los datos del padre
            this.localProveedores = JSON.parse(JSON.stringify(this.operarios));
        }
    },
    watch: {
        id() { 
            //this.localProveedores = [];
            //this.cargardatos();
        },
        cargar() { 
            //this.cargardatos();
        },
        recargar() {
            //this.cargardatos();
        },
        operariosingular(newVal) {
            if (newVal) {
                // Si 'operariosingular' cambia a verdadero, cargamos los datos nosotros mismos
                this.cargardatos();
            } else {
                // Si 'operariosingular' cambia a falso, usamos los datos del padre si están disponibles
                if (this.operarios && Object.keys(this.operarios).length > 0) {
                    this.localProveedores = JSON.parse(JSON.stringify(this.operarios));
                }
            }
        },
        operarios(newVal) {
            if (newVal && Object.keys(newVal).length > 0 && !this.operariosingular) {
                // Si 'operarios' cambia y 'operariosingular' es falso, actualizamos 'localOperarios'
                this.localProveedores = JSON.parse(JSON.stringify(newVal));
            }
        },
    }
})
</script>
