<template>
    <div class="callout callout-warning">
        <div class="row">
            <div class="col">
                <div class="input-group" :title="$t('general.especialidad')">
                    <div class="input-group-prepend">
                        <span class="input-group-text">{{$t('general.especialidad')}}</span>
                    </div>
                    <select  v-model="especialidadseleccionado" class="form-control input-sm">
                        <option v-if="isLoadingEspecialidades" disabled>{{ $t('general.cargando') }}</option>
                        <option v-else v-for="especialidad in especialidadesoperario" :key="especialidad.id" :value="especialidad.id">{{especialidad.nombre}}</option>
                    </select>
                </div>
            </div>
            <div class="col">
                <div class="input-group" :title="$t('general.cliente')">
                    <div class="input-group-prepend">
                        <span class="input-group-text">{{$t('general.cliente')}}</span>
                    </div>
                    <select v-model="clienteseleccionado" class="form-control input-sm">
                        <option v-if="isLoadingClientes" disabled>{{ $t('general.cargando') }}</option>
                        <option v-else :value="asegurado.idcliente">{{$t('general.asegurado')}} {{asegurado.nombre}}</option>
                     <option  v-for="perjudicado in perjudicados" :key="perjudicado.idcliente" :value="perjudicado.idcliente">{{$t('general.perjudicado')}} {{perjudicado.nombre}}</option>
                    </select>
                </div>
            </div>
            <div class="col">
                <div class="input-group" >
                    <div class="input-group-prepend">
                        <span class="input-group-text">{{$t('general.visita')}}</span>
                    </div>
                    <select v-model="visitaseleccionado" class="form-control input-sm">
                        <option v-if="isLoadingVisitas" disabled>{{ $t('general.cargando') }}</option>
                        <option v-else v-for="visitas in visit" :value="visitas.visita.idvisitador" :key="visitas">{{visitas.visita.datos.fecha.split("-").reverse().join("-")}} - {{visitas.visita.datos.hora}} - ({{visitas.visita.nombre_especialidad }})</option>
                    </select>
                </div>
            </div>
            <div class="col">
                <div class="input-group" >
                    <div class="input-group-prepend">
                        <span class="input-group-text">{{$t('general.destino')}}</span>
                    </div>
                    <select v-model="destinatarioseleccionado" class="form-control input-sm">
                        <option value=''>{{ $t('general.a cliente') }}</option>
                        <option value='Operario'>{{ $t('general.a proveedor') }}</option>
                    </select>
                </div>
            </div>
            <!-- Campo de cantidad con tamaño reducido -->
            <div class="col-auto">
                <div class="input-group">
                    <div class="input-group-prepend">
                        <span class="input-group-text">{{ $t('general.cantidad') }}</span>
                    </div>
                    <input type="number" v-model.number="cantidad" min="1" class="form-control input-sm" style="max-width: 70px;" />
                </div>
            </div>
            <div class="col">
                <button type="button" class="btn btn-primary " @click="nuevotrabajo()" :disabled="botonDeshabilitado">{{ $t('general.anadir') }}</button>
            </div>
        </div>
    </div>
</template>
<script>
import { PwgsApi } from '../../../../services/PwgsApi';
export default ({
    props: ['id','servicio','proveedor'],
    setup() {
        return;
    },
    data() {
        return {
            clientes: '',
            nombre: '',
            perjudicados: '',
            asegurado: '',
            visit: '',
            clienteseleccionado: '',
            visitaseleccionado: '',
            especialidadseleccionado: '',
            destinatarioseleccionado: '',
            especialidadesoperario: [],
            especialidadestotal:[],
            cantidad: 1, // Valor por defecto
            botonDeshabilitado: false,
            isLoadingEspecialidades: true,
            isLoadingClientes: true,
            isLoadingVisitas: true,
        };
    },
    methods: {
        cargardatos() {
            this.listadoespecialidades();
            this.listadoclientes();
            this.listadovisitas();
        },
        async nuevotrabajo() { 
        //POST  modulos/pwgsapi/index.php/trabajos/:id_servicio/crear-trabajo
            const api = new PwgsApi();
            let subidadatos = {
                destinatario: this.destinatarioseleccionado,
                id_cliente: this.clienteseleccionado,
                id_especialidad: this.especialidadseleccionado,
                id_proveedor: this.$props.proveedor,
                id_visita: this.visitaseleccionado
            };

            let errores = false;

            // Deshabilitar el botón mientras se realizan las peticiones
            this.botonDeshabilitado = true;

            // Llamar al endpoint tantas veces como indique la cantidad
            for (let i = 0; i < this.cantidad; i++) {
                try {
                    await api.post('trabajos/' + this.$props.id + '/crear-trabajo', subidadatos);
                } catch (error) {
                    console.error('Error al crear el trabajo:', error);
                    this.$toast.add({ severity: 'error', summary: "Error al crear el trabajo", detail: error.message || error, life: 5000 });
                    errores = true;
                    break; // Salir del bucle si ocurre un error
                }
            }

            // Habilitar el botón nuevamente
            this.botonDeshabilitado = false;

            if (!errores) {
                // Mostrar el toast de éxito
                this.$toast.add({ severity: 'success', summary: "Trabajos agregados", detail: "Los trabajos han sido agregados correctamente.", life: 5000 });
            }

            // Reiniciar la cantidad a 1 después de añadir
            this.cantidad = 1;

            // Recargar datos o emitir un evento si es necesario
            this.$parent.cargardatos();
        },
        async listadoespecialidades()
        {
            //GET /index.php/operarios/:id/especialidades
            //GET modulos/pwgsapi/index.php/especialidades

            let listadoesp = [];
            let aux = '';
            const api = new PwgsApi();
            //let especialidadesnoespecificas = esp.no_especificas.datos;
            aux = await api.get('especialidades');
            listadoesp = aux.datos;

            for (let j = 0; j < listadoesp.length; j++){
                    this.especialidadesoperario.push(listadoesp[j]);
            }
            this.isLoadingEspecialidades = false;

        },
        async listadoclientes(){ 
            //GET  modulos/pwgsapi/index.php/servicios/:id/clientes
            let cli = '';
            const api = new PwgsApi();
            console.log('entraclientesact');
            cli = await api.get('servicios/' + this.$props.id + '/clientes');
            this.perjudicados = cli.perjudicados;
            this.asegurado = cli.asegurado; 
            this.isLoadingClientes = false;           
        },
        async listadovisitas() { 
           //GET  modulos/pwgsapi/index.php/servicios/:id/visitas
            this.visit = '';
            const api = new PwgsApi();
            this.visit = await api.get('servicios/' + this.$props.id + '/visitas');
            this.isLoadingVisitas = false;
        }
    },
    mounted() {
        this.listadoespecialidades
        this.cargardatos();
    },
    watch: {
        id() {
            this.listadoespecialidades

        }
    }
})
</script>